@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');


::selection{
  color: #fff;
  background: #664AFF;
}

.wrapper{
  margin: 10px 0px 10px 0px;
  width: 100%;
}

.textarea{
  background: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.12);
}

textarea{
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px 10px 10px 20px; 
  font-size: 18px;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}

.textarea.active textarea{
  border-radius: 5px 5px 0 0;
}


