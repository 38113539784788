.timer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.countdown_timer_div {
    display: inline-block;
    min-width: 90px;
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
}

.countdown_timer_div_small {
    display: inline-block;
    border-radius: 5px;
    margin: 6px;
    margin-top: 0px;
}