.myButton {
	box-shadow:white;
	border-radius:3px;
	border:1px solid #78829e;
	display:inline-block;
	color:#ffffff;
	font-family:Arial;
	font-weight: bold;
	letter-spacing: 2px;
	text-decoration:none;
	text-shadow:0px 1px 0px #283966;
}
.myButton:hover {
	background:linear-gradient(to top, #4b4b4b 5%, #6e6f70 100%);
}
.myButton:active {
	position:relative;
	top:1px;
}