.auth-bg {
    background: rgb(244, 245, 245);
    border-radius: 10px;
    height: auto;
}
.btn1 {
    border: none;
    outline: none;
    height: 50px;
    width: 100%;
    background: black;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    transition: 0.5s;
}

.btn1:hover {
    background: rgb(83, 83, 83);
    border: 1px solid rgb(83, 83, 83);

}