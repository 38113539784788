@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
::selection{
  color: #fff;
  background: #664AFF;
}

.wrapper{
  width: 100%;
}

.wrapper .input{
  background: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.12);
}

.input input{
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}

.input.active input{
  border-radius: 5px 5px 0 0;
}


