.ad__desc {
    font-size: smaller;
}
.searchItem__location {
    font-size: smaller;
}
.searchItem__timestamp {
    font-size: smaller;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}