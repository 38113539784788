/* .dropdown .dropdown-menu {
    display: none;
    opacity: 0;
    border: 3px solid #d1d1d1;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -10px;
    transition: 0.5s ease-out;
} */

.navbar a.nav-btn {
    font-weight: 800;
    
}

#navbarProfileDropdown::after {
    display: none;
}

.fullScreen {
    background: #999999;
    padding: 0px 10px 0px 10px;
    margin-top: 2px;
    font-size: x-large;
    border-radius: 10%;
    color: white;
    cursor: pointer;
}

.fullScreen:hover {
    background: #7e7e7e;
}

/* .dropdown:hover .dropdown-menu {
    margin-top: -10px;
    display: block;
    opacity: 1;
    border: 3px solid #d1d1d1;
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown .dropdown-menu .dropdown-item {
    border: 2px solid #d1d1d1;
    padding: 10px!important;
    font-weight: 600;
    background: rgb(255, 255, 255);
    color: black;
}
.dropdown:hover .dropdown-menu .dropdown-item:hover {
    background: #d4d4d4;
} */

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: rgb(6, 68, 110);
}

a:hover {
    text-decoration: none;
    color: rgb(204, 238, 253);
}

.header {
    border-bottom: 1px solid #E2E8F0;
    background: rgb(32, 159, 218);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2rem 1.8rem;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    position: relative;
}

.nav-item {
    margin-left: 1.5rem;
}

.navbar__right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar__right .navbar-nav {
    display: flex;
    flex-direction: row;
}

.nav-link {
    font-size: 1rem;
    font-weight: 700;
}

.nav-link:hover {
    color: rgb(255, 255, 255);
}

.nav-logo {
    font-size: 2.1rem;
    font-weight: 700;
    color: white
}


.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    border-top: 0;
}




@media only screen and (max-width: 768px) {
    ul {
        padding-left: 0%;
    }

    .navbar__right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .navbar__right .navbar-nav {
        display: flex;
        flex-direction: row;
    }

    .nav-menu {
        position: absolute;
        left: -100%;
        top: 4rem;
        flex-direction: column;
        background-color: rgb(122, 204, 241);
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
        z-index: 1;
    }

    .nav-menu.active {
        left: 0;
        z-index: 1;
    }

    .nav-item {
        margin: 0.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }
    
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}




